import './assets/css/style.css'
import './assets/css/main.css'
import './App.css'
import './assets/login/css/font-awesome.css'
import Logo from './assets/img/img.png'
import Router from "./Router";
import {BrowserRouter} from 'react-router-dom';
import PwaInstallPopupIOS from 'react-pwa-install-ios';

function App() {
    return (
        <div className="App">
            <PwaInstallPopupIOS
                delay={3}
                lang="en"
                appIcon={Logo}>
            </PwaInstallPopupIOS>
            <BrowserRouter>
                <div className="App">
                    <Router/>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
