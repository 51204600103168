import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import HomeView from "./views/HomeView";
import NotFound from "./views/HomeView";
import LoginView from "./views/LoginView";

class Router extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={HomeView}/>
                <Route path="/login" component={LoginView}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }
}

export default Router;