import '../assets/css/style.css'
import {Avatar, Badge, Button, Card, Col, Input, Layout, Modal, Popover, Radio, Row, Tooltip, Typography} from "antd"
import '../assets/css/main.css'
import {BellOutlined, MailOutlined, PoweroffOutlined} from "@ant-design/icons"
import React, {useEffect, useState} from 'react'
import {useFormik} from "formik";
import '../App.css'
import {
    useGetCheck,
    UseGetPosition,
    useGetPresensi,
    useGetUsername,
    useGetWeather,
    UsePresensi,
    useSmartMenu
} from "../services/api";
import Cookies from 'js-cookie';
import IMGItemLogo from "../assets/img/logo3.png"
import Swal from "sweetalert2";
import {useHistory} from 'react-router-dom';
import Loader from '../assets/img/loader.gif'
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Marquee from "react-fast-marquee";
import {checkCookie, prosesLogout, timeOutPopUp, options} from "../function/Fungsi";

function HomeView() {

    // local definition
    const history = useHistory()
    const day = new Date();
    let hour = day.getHours();
    const {md} = useBreakpoint()

    // local state
    const [modalAbsensi, setModalAbsensi] = useState(false);
    const [infoStatus, setInfoStatus] = useState(false)
    const [newsStatus, setNewsStatus] = useState(false)
    const [dataPosisi, setDataPosisi] = useState([])
    const [coor, setCoor] = useState({
        long: "",
        lat: "",
    })

    // hooks
    const {data: dataVerifikasi, isLoading: dataVerivicationLoad} = useGetCheck(Cookies.get('portal_msvstudio'))
    const {data: userName, isLoading: userLoad} = useGetUsername(Cookies.get('portal_msvstudio'))
    const {data, isLoading: menuLoad} = useSmartMenu(Cookies.get('portal_msvstudio'))
    const {data: statusPresensi, isLoading: statusPresensiLoad} = useGetPresensi(Cookies.get('portal_msvstudio'))
    const {data: currentWeather, isLoading: currentWeatherLoad} = useGetWeather()

    // handle
    const handleVisibleChange = () => setInfoStatus(!infoStatus);
    const handleVisibleChangeNews = () => setNewsStatus(!newsStatus);
    const handleCancel = () => setModalAbsensi(false);

    const handleSubmit = (val, rec) => {
        val.lokasi = `${dataPosisi[0]?.street}, ${dataPosisi[0]?.adminArea5}, ${dataPosisi[0]?.adminArea3}`
        val.username = val.username[0].username
        const absent = UsePresensi(val)

        absent.then(() => {
            setModalAbsensi(false)
        })
    }

    //console.log(currentWeather)

    const locationDetect = () => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({name: "geolocation"})
                .then(
                    (result) => {
                        if (result.state === "granted") {
                            navigator.geolocation.getCurrentPosition(success);
                        } else if (result.state === "prompt") {
                            navigator.geolocation.getCurrentPosition(success, errors, options);
                        } else if (result.state === "denied") {
                            navigator.geolocation.getCurrentPosition(errors);
                        }
                        result.onchange = function () {
                        };
                    });
        } else {
            alert("Sorry Not available!");
        }
    }
    const currentPosition = (lat, lon) => {
        UseGetPosition(lat, lon).then((resp) => {
            setDataPosisi(resp?.data)
        })
    }

    // destination
    const success = (pos) => {
        const crd = pos.coords;
        setCoor({
            lat: crd.latitude,
            long: crd.longitude
        })
        currentPosition(crd.latitude, crd.longitude)
    }

    const errors = () => {
        Swal.fire(
            'Location Access Needed',
            'Please Allow Sites to Access your Location',
            'warning'
        )
    }

    // provider definition
    const initialValues = {
        username: userName,
        email: "",
        kondisi: true,
        keterangan: "",
        lat: coor.lat,
        lon: coor.long,
        lokasi: dataPosisi,
    }
    const frmk = useFormik({
        onSubmit: handleSubmit, initialValues, enableReinitialize: true,
    })

    //Cek Internet
    //const [isOnline, setOnline] = useState(window.navigator.onLine);


    // effect

    useEffect(() => {
        checkCookie(history)
        //ini untuk get lokasi saat ini
        //locationDetect()
    }, [])
    useEffect(() => {
        if (dataVerifikasi?.results === "ERROR" || dataVerifikasi?.results === "DENIED") {
            prosesLogout()
        //console.log(dataVerifikasi);
        }
        if (dataVerifikasi?.results === "") {
            timeOutPopUp()
        }
    }, [dataVerifikasi])
    useEffect(() => {
            if (statusPresensi?.length === 0) {
                setInfoStatus(true)
            }
        },
        [statusPresensi]
    )



    return (
        <Layout style={{background: "transparent"}}>
            <Row>
                <Col span={24}>
                    <Card
                        className="main-menu"
                        style={{
                            maxWidth: 700,
                            borderRadius: "1.5rem",
                            paddingTop: 0,
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            margin: "auto",
                            marginTop: "20vh",
                            background: "#FFFFFF99",
                            border: "none"
                        }}>
                        <>
                            <Row align={"middle"} gutter={[6, 14]}>
                                <Col xs={24} md={6} xl={6} style={md ? {textAlign: "start"} : {textAlign: "center"}}>
                                    <Avatar src={IMGItemLogo}/>
                                    <span style={{fontSize: 12, paddingLeft: 10}}>     MSV Portal</span>
                                </Col>
                                <Col xs={24} md={10} xl={10} style={{textAlign: "center"}}>
                                <span style={{
                                    fontSize: 12,
                                    paddingRight: 10
                                }}>     {currentWeather?.location?.name +", " + currentWeather?.location?.region}</span>
                                </Col>
                                <Col xs={24} md={8} xl={8}><Avatar src={currentWeather?.current?.condition?.icon}/>
                                    <span style={{
                                        fontSize: 12,
                                        paddingLeft: 5
                                    }}>     {currentWeather?.current?.feelslike_c + "°C, " + currentWeather?.current?.condition?.text}</span></Col>
                            </Row>
                            <hr style={{background: "white"}}/>
                            <Row style={{paddingBottom: "1rem"}}>
                                <Col span={12} style={{textAlign: "start"}}>
                                 <span style={{
                                     fontSize: 12,
                                     fontWeight: 700
                                 }}>Aplikasi kamu</span>
                                </Col>
                                <Col span={12} style={{textAlign: "end"}}>
                                    <Popover
                                        title="Informasi"
                                        visible={newsStatus}
                                        onVisibleChange={handleVisibleChangeNews}
                                        placement="topLeft"
                                        content={
                                            <Row>
                                                <p style={{color: "grey"}}>Belum ada berita</p>
                                            </Row>
                                        }
                                    >
                                        <Button type={"link"} style={{color: "#dbb567"}}
                                                shape="circle"
                                                icon={<BellOutlined/>}
                                        />
                                    </Popover>
                                </Col>
                            </Row>
                            <div className="container">
                                {data?.length !== 0 ?
                                    <Row gutter={[20, 20]}>
                                        {data?.map((data, idx) =>
                                            <Col key={data?.name + idx} xs={12} md={4} className={"item-menu"}>
                                                <a href={data.url} key={data?.name + idx}
                                                   style={{textDecoration: "none", color: "#212124"}}>
                                                    <Avatar size={54} src={`/icon/${data?.icon}.png`}/>
                                                    <div style={{fontSize: 12}}>{data?.name}</div>
                                                </a>
                                            </Col>
                                        )}
                                    </Row>
                                    : <div className="container">
                                        <div className="row">
                                            <div className="col min-vh-100">
                                                <img className="img-fluid" src={Loader} alt="loader"/>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <hr style={{background: "white"}}/>
                            {!userLoad ?
                                <Row align={"middle"}>
                                    <Col xs={24} sm={12} lg={2} md={2}
                                         style={md ? {textAlign: "start"} : {textAlign: "center"}}>
                                        <Popover
                                            title="Status"
                                            visible={infoStatus}
                                            onVisibleChange={handleVisibleChange}
                                            placement={md ? "topLeft" : "top"}
                                            content={
                                                <Row>
                                                    {(typeof (statusPresensi) !== "undefined" && statusPresensi?.length === 0) ?
                                                        <Col>
                                                            <p>Kamu belum absen hari ini</p>
                                                        </Col>
                                                        :
                                                        <>
                                                            <Col span={12}>Jam</Col>
                                                            <Col span={12}
                                                                 style={{textAlign: "end"}}>{statusPresensi?.jam}
                                                            </Col>
                                                            <Col span={12}>Status</Col>
                                                            <Col span={12}
                                                                 style={{textAlign: "end"}}>{statusPresensi?.posisi}</Col>
                                                        </>
                                                    }
                                                </Row>
                                            }
                                        >
                                            {typeof (statusPresensi) !== "undefined" &&
                                                <Badge size={"large"} dot={true}
                                                       style={(statusPresensi?.length === 0) ? {
                                                           background: "red",
                                                           padding: 5
                                                       } : {background: "green", padding: 5}}>
                                                    <Avatar src={userName[0]?.link}/>
                                                </Badge>
                                            }

                                        </Popover>
                                    </Col>
                                    <Col xs={24} sm={12} lg={6} md={6}> <span
                                        style={md ? {
                                            textAlign: "start",
                                            fontSize: 12,
                                        } : {
                                            textAlign: "center",
                                            fontSize: 12,
                                        }}>{userName[0]?.nama}</span></Col>
                                    <Col xs={24} sm={12} lg={14} md={14}
                                         style={md ? {textAlign: "start"} : {textAlign: "center"}}>
                                        {!userLoad &&
                                            <>
                                                <Marquee
                                                    pauseOnHover={true}
                                                    gradient={false}
                                                    speed={35}
                                                >
                                             <span style={{
                                                 fontSize: 12,
                                                 paddingLeft: "10rem",
                                             }}>    {`${hour <= 10 ? "Selamat pagi" : hour <= 12 ? "Selamat siang" : hour <= 16 ? "Selamat sore" : "Halo..."} ${userName[0]?.username}${hour <= 7 ? ", Jam kerja MSV Studio belum dimulai :)" : hour <= 10 ? ", Semangat Bekerja, Jangan lupa absensi ya :D" : hour <= 12 ? ", Tetap semangat ya... :D" : hour <= 13 ? ", Jangan lupa istirahat :D" : hour <= 14 ? ", Selamat bekerja kembali :)" : hour <= 16 ? ", Tetap Semangat ya, jangan lupa isi Logbooknya ya :)" : ", Jam kerja MSV Studio sudah selesai, jangan lupa isi Logbooknya :)"}`}
                                             </span>
                                                </Marquee>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={24} sm={24} lg={2} md={2}
                                         style={md ? {textAlign: "end"} : {textAlign: "center"}}>
                                        <Tooltip placement="top" title={"Keluar dari aplikasi"}>
                                            <Button onClick={() => prosesLogout()} type={"link"}
                                                    style={{color: "#f50"}}
                                                    shape="circle"
                                                    icon={<PoweroffOutlined/>}/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                : <span> - </span>}
                        </>
                    </Card>
                </Col>
            </Row>
            <Modal
                footer={null}
                title={""}
                style={{borderRadius: "1rem"}}
                visible={modalAbsensi}
                onCancel={handleCancel}
                width="20rem"
            >
                <Row gutter={[14, 14]}>
                    <Col span={24}>
                        <Typography style={{fontWeight: 500, paddingBottom: ".5rem"}}>Lokasi kamu saat ini
                            :</Typography>
                        <Typography
                            style={{fontWeight: 600}}>
                            {/*{dataPosisi.length !== 0 ? `${dataPosisi[0]?.street}, ${dataPosisi[0]?.adminArea5}, ${dataPosisi[0]?.adminArea3}` : "Pastikan akses lokasi Anda diizinkan"}*/}
                            {coor.length !== 0 ? `Longitude : ${coor.long} \n Latitude : ${coor.lat}` : "Pastikan akses lokasi Anda diizinkan"}

                        </Typography>
                        {/*style={{fontWeight: 600}}>{`Lat : ${coor.lat}, Lon : ${coor.long}`}</Typography>*/}
                        <p style={{color: "red", fontSize: 10, marginBottom: 0}}>posisi mungkin saja tidak akurat,
                            pastikan posisi kamu dekat dengan data deteksi</p>
                    </Col>
                    <Col span={24}>
                        <Typography style={{fontWeight: 500, paddingBottom: ".5rem"}}>Bagaimana kondisi kesehatan kamu
                            ?</Typography>
                        <Radio.Group defaultValue={frmk.values.kondisi}
                                     onChange={(e) => frmk.setFieldValue("kondisi", e.target.value)}>
                            <Radio.Button style={{borderTopLeftRadius: "1rem", borderBottomLeftRadius: "1rem"}}
                                          value={true}>Baik</Radio.Button>
                            <Radio.Button style={{borderTopRightRadius: "1rem", borderBottomRightRadius: "1rem"}}
                                          value={false}>Buruk</Radio.Button>
                        </Radio.Group>
                    </Col>
                    {!frmk.values.kondisi &&
                        <Col span={24}>
                            <Input.TextArea value={frmk.values.keterangan}
                                            onChange={(e) => frmk.setFieldValue("keterangan", e.target.value)} rows={4}
                                            placeholder="Isi keterangan tidak sehat"
                                            style={{borderRadius: ".75rem", outline: "none"}} size="small"
                            />
                        </Col>
                    }

                    <Col span={24}>
                        <Input placeholder="Email"
                               value={frmk.values.email}
                               onChange={(e) => frmk.setFieldValue("email", e.target.value)}
                               style={{height: 45, borderRadius: ".75rem", outline: "none"}} size="small"
                               prefix={<MailOutlined/>}/>
                    </Col>

                    <Col span={24}>
                        <hr/>
                        {(coor.length !== 0 && userName) ?
                            <Button onClick={frmk.handleSubmit} type={"primary"} size="large"
                                    style={{borderRadius: "1rem"}}
                                    block>Presensi</Button>
                            :
                            <Button disabled type={"primary"} size="large" style={{borderRadius: "1rem"}}
                                    block>Menunggu Lokasi</Button>
                        }
                    </Col>
                </Row>
            </Modal>
        </Layout>
    );
}

export default HomeView;
