import axios from "axios";
import {useQuery} from "react-query";

const baseUrl = 'https://api.msvstudio.co.id/portal/'
const baseUrlLogin = 'https://api.msvstudio.co.id/login/'
const baseUrlLoginAuth = 'https://auth.msvstudio.co.id/presensi/'
const baseurlPresensi = 'https://api.msvstudio.co.id/presensi/'


const getDate = (data) => {
    if (data < 10) return "0" + data
    return data
}

const curDate = new Date()
const myKey = `msvstudio${curDate.getFullYear()}-${getDate(curDate.getMonth() + 1)}-${getDate(curDate.getDate())}`

export const useSmartMenu = (username) => {
    const query = useQuery('keySmartMenu', async () => {
            return await axios.get(`${baseUrl}menu`, {
                headers: {
                    'username': username
                }
            }).then(res => {
                return res.data;
            })
        }, {refetchInterval: 5000}
    )
    return {
        ...query,
        data: query.data
    }
}

export const useGetUsername = (dataCookie) => {
    const query = useQuery('keyName', async () => {
            return await axios.get(`${baseUrl}`, {
                headers: {
                    'username': dataCookie
                }
            }).then(res => {
                return res.data;
            })
        }, {refetchInterval: 5000}
    )
    return {
        ...query,
        data: query.data
    }
}

export const useGetPresensi = (dataCookie) => {
    const query = useQuery('keyPresensi', async () => {
            return await axios.get(`${baseurlPresensi}`, {
                headers: {
                    'username': dataCookie
                }
            }).then(res => {
                return res.data;
            })
        }, {refetchInterval: 5000}
    )
    return {
        ...query,
        data: query.data
    }
}


export const UseLogin = async (dataLogin) => {
    try {
        const res = await axios.get(`${baseUrlLogin}`, {
            headers: {
                'username': dataLogin.username,
                'password': dataLogin.password,
            }
        })
        return res.data;
    }catch (err) {
        const res = await axios.get(`${baseUrlLoginAuth}`, {
            headers: {
                'username': dataLogin.username,
                'password': dataLogin.password,
            }
        })
        return res.data;
    }
};

export const UseGetPosition = async (lat, lon) => {
    const res = await axios.get(`https://api.msvstudio.co.id/portal/getLokasi`, {
        headers: {
            'lat': lat,
            'lon': lon,
        }
    })
    return await res.data

}

export const UsePresensi = async (data) => {
    const myData = new FormData()
    myData.append("key", myKey)
    myData.append("username", data.username)
    myData.append("email", data.email)
    myData.append("kondisi", data.kondisi)
    myData.append("keterangan", data.keterangan)
    myData.append("lat", data.lat)
    myData.append("lon", data.lon)
    myData.append("lokasi", data.lokasi)
    const res = await axios.post(`https://app.msvstudio.co.id/api/setPresensi`, myData)
    return res.data;

}


export const useGetWeather = () => {
    const query = useQuery('keyWeather', async () => {
            return await axios.get(`https://api.weatherapi.com/v1/current.json?key=40c5fcce261e4c28aa143741210112&q=Sleman&aqi=no`).then(res => {
                return res.data;
            })
        }, {refetchInterval: 300000}
    )
    return {
        ...query,
        data: query.data
    }
}


export const useGetCheck = (cookie) => {
    const myData = new FormData()
    myData.append("username", cookie)
    myData.append("key", myKey)

    const query = useQuery('keyCheck', async () => {
            return await axios.post(`${baseUrlLogin}cek`, myData).then(res => {
                return res.data;
            })
        }, {refetchInterval: (300000)}
    )
    return {
        ...query,
        data: query.data
    }
}
