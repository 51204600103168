import {nama_cookie} from "../variables/baseConfig";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";

export const checkCookie = (history) => {
    let cookies = Cookies.get(nama_cookie)
    if (typeof cookies === "undefined" || cookies === "" || cookies?.length === 0) {
        //window.location.href = "https://portal.msvstudio.co.id/auth/";
        window.location.assign('/login')
    	//console.log(cookies);
    }
	//console.log(nama_cookie);
//console.log(cookies);
}

export const prosesLogout = () => {
    Cookies.remove(nama_cookie, {domain: '.msvstudio.co.id'})
    window.location.assign('/login')
}

export const timeOutPopUp = () => {
    Swal.fire(
        'Sesi Anda telah habis!',
        'Silahkan Login kembali',
        'error'
    ).then((res) => {
            prosesLogout()
        }
    )
}

export const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

