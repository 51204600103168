import {UseLogin} from "../services/api";
import {nama_cookie} from "../variables/baseConfig";
import React, {useEffect, useState} from 'react'
import {useFormik} from "formik";
import {useHistory} from 'react-router-dom';
import {Button, Card, Col, Input, Layout, Row} from "antd"
import {SafetyOutlined, UserOutlined} from "@ant-design/icons"
import IMGItemLogo from "../assets/img/logo.png"
import Cookies from 'js-cookie'
import Swal from "sweetalert2";
import {checkCookie} from "../function/Fungsi";


const LoginView = () => {

    const [errorUsername, setErrorUsername] = useState(false)
    const [errorPassword, setErrorPassword] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [dataLogin, setDataLogin] = useState([])
    const history = useHistory()

    // const {data, isLoading} = useLogin(frmk.values)


    const initialValues = {
        "username": "", "password": ""
    }


    //Fungsi Get
    const getDataLogin = (val) => {
        setIsLoaded(false)
        UseLogin(val)
            .then((res) => {
                //Cek Data ada atau tidak ?
                if (res.length === 0) {
                    alert("Username / Password Salah !!!")
                } else {
                    //Cookies.set(nama_cookie, res[0]?.username, {domain: '.msvstudio.co.id', expires: 1});
                    //setDataLogin(res)
                    //setIsLoaded(true)
                	
    				Cookies.set(nama_cookie, res[0]?.username, {
      					domain: '.msvstudio.co.id',
                    	expires: 1,
                    	path:"/"
    				});
                	setDataLogin(res)
                    setIsLoaded(true)
                    history.push("/");
                }
            }).catch(() => {
            //Jika Tidak Dapat Data
            alert("Connection Timeout, SSL Bermasalah !!!")
        })
    }

    const handleSubmit = (val, rec) => {
        const idle = (val.username === "" || val.password === "")
        if (val.username === "") setErrorUsername(true)
        if (val.password === "") setErrorPassword(true)
        if (idle) return
        //API LOGIN
        //const resp = useLogin(val)
        getDataLogin(val)
    }

     useEffect(() => {
         //checkCookie(history)
     }, [])

    const frmk = useFormik({
        onSubmit: handleSubmit, initialValues, enableReinitialize: true,
    })

    return (<Layout style={{background: "transparent"}}>
            <Row align={"middle"} justify={"center"}>
                <Col span={24}>
                    <Card
                        style={{
                            width: 350,
                            borderRadius: "1.5rem",
                            paddingTop: 0,
                            paddingLeft: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: "1rem",
                            margin: "auto",
                            marginTop: "20vh",
                            background: "#FFFFFF99",
                            border: "none"
                        }}>
                        <Row gutter={[14, 14]} style={{marginBottom: "1rem"}} justify={"center"} align={"middle"}>
                            <Col span={24}>
                                <img alt="logo" src={IMGItemLogo} height={125}/>
                            </Col>
                        </Row>
                        <Row gutter={[14, 14]}>
                            <Col span={24}> <Input value={frmk.values.username}
                                                   onChange={e => frmk.setFieldValue('username', e.target.value)}
                                                   onFocus={() => setErrorUsername(false)}
                                                   style={{height: 50, borderRadius: "1rem"}} size="large"
                                                   placeholder="Username" prefix={<UserOutlined/>}/>
                                {errorUsername && <div style={{color: 'red'}}>Username Wajib Diisi</div>}
                            </Col>
                            <Col span={24}> <Input.Password value={frmk.values.password}
                                                            onChange={e => frmk.setFieldValue('password', e.target.value)}
                                                            onFocus={() => setErrorPassword(false)}
                                                            onPressEnter={frmk.handleSubmit}
                                                            style={{height: 50, borderRadius: "1rem"}} size="large"
                                                            placeholder="Password" prefix={<SafetyOutlined/>}/>
                                {errorPassword && <div style={{color: 'red'}}>Password Wajib Diisi</div>}</Col>
                            <Col span={24}><Button size="large" block
                                                   type="primary"
                                                   style={{
                                                       background: "#eda72c",
                                                       border: "none",
                                                       borderRadius: "1rem",
                                                       height: 50
                                                   }}
                                                   onClick={frmk.handleSubmit}

                            >Masuk</Button></Col>
                        </Row>
                    </Card>,
                </Col>
            </Row>
        </Layout>
    )
}

export default LoginView